var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { width: 500, title: "提示", footer: null },
          on: { cancel: _vm.handleCancel },
          model: {
            value: _vm.showPopup,
            callback: function($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup"
          }
        },
        [
          _c("div", { staticClass: "color-red font-weight-bold" }, [
            _vm._v(" 此评级币之前审核为"),
            _c("span", { staticClass: "color-blue" }, [
              _vm._v("【" + _vm._s(_vm.lastTimeCheckStatusMessage) + "】")
            ]),
            _vm._v("，交易完成，不可驳回，请重新审核！！！ ")
          ]),
          _c(
            "div",
            { staticClass: "flex-center-center mt-20" },
            _vm._l(_vm.checkDataList, function(item) {
              return _c(
                "a-popconfirm",
                {
                  key: item.value,
                  attrs: {
                    title: "确认为" + item.name + "吗?",
                    "ok-text": "Yes",
                    "cancel-text": "No"
                  },
                  on: {
                    confirm: function($event) {
                      return _vm.handleCheckAuctionStatus(item)
                    }
                  }
                },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "mr-10",
                      attrs: { size: "small", type: item.btnType }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                ],
                1
              )
            }),
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }