import instance from "@/axios"

/** 获取退款订单列表数据API */
export const getOrderReturnListApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/returnsList',
        method: 'GET',
        params: params
    })
}

/** 获取退款理由 */
export const getReturnReasonListApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/getOrderReturnsTypeList',
        method: 'GET',
        params: params
    })
}

/** 获取举证，留言列表 */
export const getUserVoucherListApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProof/list',
        method: 'GET',
        params: params
    })
}

/** 获取退款订单详情数据 */
export const getReturnOrderDetailInfoApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/getOrderReturnDetail',
        method: 'get',
        params: params
    })
}

/** 修改退款理由 */
export const postChangeReturnPriceReasonApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/editReceiptOrReturnTypeV1',
        method: 'get',
        params: params
    })
}
/** 确定修改 */
export const postConfirmChangeButtonApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/operationRefund',
        method: 'post',
        data: params
    })
}
/** 客服添加凭证相关 */
export const postConfirmAddVoucherApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProof/addOrderProof',
        method: 'post',
        data: params
    })
}

/** 修改退款物流费用 */
export const postChangeReturnLogisticsPriceApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/editLogisticsFee',
        method: 'get',
        params: params
    })
}

/** 标记已处理 */
export const postServiceTagActionOverApi = async (id, status) => {
    return instance({
        url: '/dq_admin/orderProduct/markStatus',
        method: 'post',
        data: {
            markStatus: status,
            ids: [id]
        }
    })
}

/** 添加无法交付 */
export const postSellerNotCanSendProductApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/addNotDeliveryLog',
        method: 'get',
        params: params
    })
}

/** 延长商家确认收货API */
export const getProlongUserTakeOverProductApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/bidOrderReturnsAffirmReceivingDelay',
        method: 'get',
        params: params
    })
}

/** 确定进行标记或取消标记 */
export const postRateServiceTagActionOverApi = async (params) => {
    return instance({
        url: '/dq_admin/rating/markWaitStatus',
        method: 'get',
        params: params
    })
}


/** 获取介入列表 */
export const getServiceJoinReasonListApi = async (params) => {
    return instance({
        url: '/dq_admin/orderRemark/serviceJoinRemarkList',
        method: 'get',
        params: params
    })
}

/** 获取介入原因 */
export const getServiceInterventionReasonListApi = async () => {
    return instance({
        url: '/dq_admin/orderProduct/serviceJoinReasonList',
        method: 'get',
    })
}

/** 提交介入原因 */
export const putServiceInterventionReasonApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/serviceJoinStatusUpdate',
        method: 'post',
        data: params
    })
}

/**
 * * 订单编号
 * String orderNo;
 *  * 退款单编号
 * String returnsNo;
 *  * 介入理由
 * String serviceJoinReason;
 *  * 介入完成时间
 * String serviceJoinTime;
 *  * 介入次数
 * Integer serviceJoinCount;
 *  * 订单金额
 * String orderPrice;
 *  * 历史介入原因
 * String historyServiceJoinReason;
 * */
/** key映射汉字 */
const expertJoinReasonJsonKeyMapText = {
    orderNo: '订单编号',
    returnsNo: '退款单编号',
    serviceJoinReason: '介入理由',
    serviceJoinTime: '介入完成时间',
    serviceJoinCount: '介入次数',
    orderPrice: '订单金额',
    historyServiceJoinReason: '历史介入原因',
    buyerInfo: '买家信息',
    shopInfo: '卖家信息',
    ratingCompany: '评级公司',
    coinName: '大币种'
}
/** 介入数据导出 */
export const getServiceJoinExportApi = async (params) => {
    const res = await instance({
        url: '/dq_admin/orderProduct/serviceJoinListExport',
        method: 'get',
        params: params
    })
    if (res.status !== '200') return
    if (res.data && res.data.length) {
        let tempList = []
        res.data.forEach(item => {
            let tempItem = {}
            for (let key in item) {
                tempItem[expertJoinReasonJsonKeyMapText[key]] = item[key]
            }
            tempList.push(tempItem)
        })
        res.data = tempList
        return res
    } else {
        return res
    }
}