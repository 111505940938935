<template>
  <div>
    <a-modal
        :width="500"
        v-model="showPopup"
        title="提示"
        @cancel="handleCancel"
        :footer="null"
    >
      <div class="color-red font-weight-bold">
        此评级币之前审核为<span class="color-blue">【{{ lastTimeCheckStatusMessage }}】</span>，交易完成，不可驳回，请重新审核！！！
      </div>
      <div class="flex-center-center mt-20">
        <a-popconfirm
            v-for="item in checkDataList"
            :key="item.value"
            :title="`确认为${ item.name }吗?`"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleCheckAuctionStatus(item)"
        >
          <a-button size="small" :type="item.btnType" class="mr-10">{{ item.name }}</a-button>
        </a-popconfirm>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {PRODUCT_CHECK_DATA_LIST} from "@/views/cmsPage/productManage/auctionAboutList/_services"
import {postAuctionCheckStatusApi} from "@/views/cmsPage/productManage/auctionAboutList/_apis"

export default {
  data() {
    return {
      showPopup: false,
      isloadingStatus: false,
      checkDataList: PRODUCT_CHECK_DATA_LIST,
      lastTimeCheckStatusMessage: '精品',
      productId: undefined,
    };
  },
  methods: {
    show(data) {
      this.productId = data.productId;
      this.lastTimeCheckStatusMessage = data.checkStatusMessage
      this.showPopup = true;
    },
    /** 确定评级 */
    async handleCheckAuctionStatus(item) {
      if (this.isloadingStatus) return this.$message.warn('正在修改，请稍后')
      this.isloadingStatus = true
      this.$loading.show()
      const res = await postAuctionCheckStatusApi({
        productId: this.productId,
        operType: item.value,
        secondRejectionStatus: 1,
      });
      this.$loading.hide()
      if (res.status !== '200') return
      this.isloadingStatus = false
      this.$emit('checkSuccess', this.productId)
      this.handleCancel()
    },
    handleCancel() {
      this.lastTimeCheckStatusMessage = '精品'
      this.productId = undefined
      this.showPopup = false
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
